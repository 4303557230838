import React from "react";
import Card from "react-bootstrap/Card";
import "./Areasofservice.css";

export default function Areasofservice() {
  return (
    <>
      <h1>Where we work</h1>
      <hr />
      <h4>
        <a href="#footer1">
          <span id="link">Contact us</span>
        </a>{" "}
        today to schedule a free estimate in central Ohio!
      </h4>
      <div className="container"></div>
      <div className="row">
        <div className="col-md-6 mb-2">
          <Card>
            <Card.Body>
              <Card.Text>
                Our heart is in Ohio and we work out of the middle of the
                Buckeye State! "O..H.." Feel free to{" "}
                <a href="mailto:jlstanley4527@gmail.com">contact us</a> and
                we'll see if you are in the area we cover in central Ohio.
              </Card.Text>
            </Card.Body>
            <Card.Img
              variant="top"
              src="../assets/Ohio-Map.jpg"
              alt="map of Ohio the state"
            />
          </Card>
        </div>
        <div className="col-md-6 mb-5">
          <ul id="areaList">
            Some of the places we already travel to:
            <li>Columbus</li>
            <li>Newark</li>
            <li>Chilicothe</li>
            <li>Marysville</li>
            <li>Lancaster</li>
            <li>Cambridge</li>
            <li>Washington Courthouse</li>
            <li>Zanesville</li>
            <li>Mount Vernon</li>
          </ul>
          <Card
            style={{ width: "21rem" }}
            className="text-center"
            id="daffodil"
          >
            <Card.Img
              variant="top"
              src="../assets/daffodilwindow.jpg"
              alt="picture of a window with yellow flowers in front of it"
            />
            <Card.Body>
              <Card.Text>
                We would love to chat with you about your free service estimate!
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
