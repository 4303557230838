import React from "react";
import Contact from "../Contact/Contact";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <div id="footer1" className="pt-2">
        <h3>
          Thanks so much for visiting us! We would love to get in contact with
          you.
        </h3>
        <h4>
          Give us a call at <a href="tel: +17405015382">740-501-5382</a>{" "}
        </h4>

        <Contact />
      </div>
    </>
  );
}
