import React from "react";
import "./Home.css";
// import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import ReactPlayer from "react-player";

export default function Home() {
  return (
    <>
      <div className="containerback">
        <h1>Welcome to JTS Window Cleaning and Power Washing</h1>
        <hr />
        <h2 className="mb-2">
          Top quality service at an affordable price since 1997
        </h2>
        <h4>
          <a href="#footer1">
            <span id="link">Contact us</span>
          </a>{" "}
          today to schedule a free estimate in central Ohio!
        </h4>
        <Card className="bg-dark text-white mb-2">
          <Card.Img
            src="../assets/windows2.jpg"
            alt="a beautiful house with purple and blue hues to the photo"
          />
          <Card.ImgOverlay></Card.ImgOverlay>
        </Card>
      </div>
    </>
  );
}
