import React from "react";
import "./Services.css";
import Card from "react-bootstrap/Card";

export default function Service() {
  return (
    <>
      <h1 className="mb-2">What we can do for you</h1>
      <hr />
      <h4>
        <a href="#footer1">
          <span id="link">Contact us</span>
        </a>{" "}
        today to schedule a free estimate in central Ohio!
      </h4>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-2">
            <Card>
              <Card.Body>
                <Card.Text>
                  We don't work on a contract system with you. You have the
                  freedom to choose who you work with and not be locked in. But
                  many of our customers have been with us for decades,
                  <strong> contract-free.</strong> That speaks volumes about how
                  content our customers are!
                </Card.Text>
              </Card.Body>
              <Card.Img
                src="../assets/junglewindow.jpg"
                alt="a window next to tropical plants"
              />
            </Card>
          </div>
          <div className="col-md-6 mb-2">
            <p>
              JTS has provided gold-standard window cleaning services at
              businesses all over Central Ohio since 1997. We also do
              residential work on all sizes of homes. We have had many spouses
              gift a home window cleaning service and earn some big points with
              their significant other, you should try it!
            </p>
            <p className="mb-2">Relax and watch us take care of your:</p>
            <ul className="text-center">
              <li>-window cleaning</li>
              <li>-power washing</li>
              <li>-awning cleaning</li>
              <li>-deck or patio power washing</li>
              <li>-power washing of outdoor furniture or large toys</li>
            </ul>
            <p className="mb-2">
              We work at all types of businesses from a small job for a few
              dollars up to giant clients that we work with on a regular basis.
              No need for lifts or scaffolds brought to your properties, we do
              our work from the ground with poles so there is minimal
              disturbance of your property.
            </p>
            <hr />
            <Card>
              <Card.Img
                variant="top"
                src="../assets/housewithwindows.jpg"
                alt="A large business with windows"
              />
              <Card.Body>
                <Card.Text>
                  Please fill out the form on the bottom of any of these pages,
                  and our secretary will contact you.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
