import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NavBar from "./components/NavBar/NavBar";
import Areasofservice from "./pages/Areasofservice";
import Services from "./pages/Services";
import Whoweare from "./pages/Whoweare";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer/Footer";
import "./App.css";


function App() {
  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <NavBar />
        <div className="container">
          <Routes>
            <Route index element={<Home />} />
            <Route path="/Areasofservice" element={<Areasofservice />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Whoweare" element={<Whoweare />} />
          </Routes>
        </div>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
