import React from "react";
import "./Whoweare.css";
import Card from "react-bootstrap/Card";

export default function Whoweare() {
  return (
    <>
      <h1 className="mb-2">Who we are</h1>
      <hr />
      <h2>
        We value a great relationship with our customers. So here is a brief
        introduction to our company story.
      </h2>
      <h4>
        <a href="#footer1">
          <span id="link">Contact us</span>
        </a>{" "}
        today to schedule a free estimate in central Ohio!
      </h4>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6 mb-2">
            <Card>
              <Card.Body>
                <Card.Text>
                  A family owned and run business based on principles that are
                  rare in our time-honesty, reliability and accountability to
                  you as a customer. JTS was started by Christopher Stanley and
                  he still oversees the business and works side by side with his
                  employees. He's your go-to guy to establish a great set of
                  services to keep your business or home looking sparkling and
                  fresh.
                </Card.Text>
              </Card.Body>
              <Card.Img
                variant="bottom"
                src="./assets/jessewindows.jpg"
                alt="A JTS employee happily working"
              />
              <Card.Text>
                JTS knows that keeping your windows clean and performing power
                washing is an important aspect of a healthy maintenance routine.
                They both keep your home or business fresh and remove mold,
                mildew, dirt and other nasty things that accumulate.
              </Card.Text>
            </Card>
          </div>
          <div className="col-md-6 mb-2">
            <Card>
              <Card.Body>
                <Card.Text>
                  In addition to cleaning your windows, we clean the window
                  tracks and screens as well. We don't just power wash walkways,
                  we can also help clean your patio, pool area, deck, fences,
                  vinyl siding, and patio furniture.
                </Card.Text>
              </Card.Body>
              <Card.Img
                variant="bottom"
                src="./assets/Resized_20190711_085906_425619684209616(1).jpeg"
                alt="family happily working together"
              />
              <Card.Text>
                Contact us today to set up an estimate and see how we can help
                your home or business look brighter.
              </Card.Text>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
