import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./NavBar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export default function NavBar() {
  return (
    <nav className="nav m-0 p-0 center">
      <Navbar bg="#748A9E" variant="light" expand="lg">
        <Container>
          <Navbar.Brand href="/" id="brand">
            JTS Window Cleaning
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <CustomLink to="./Areasofservice">Areas Of Service</CustomLink>
              <CustomLink to="./Services">Services</CustomLink>
              <CustomLink to="./Whoweare">Who We Are</CustomLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
