import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export async function handleContact(data){
    try {
        const docRef = await addDoc(collection(db, "contact"), data);
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}
