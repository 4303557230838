// import { useState } from "react";
// import { handleContact } from "../../utils/contactHandler";
import "./Contact.css";

// import blurCalc from "../../utils/blurCalc";

// const Contact = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [text, setText] = useState("");
//   const [blur, setBlur] = useState(10);

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!name || !email || !text) {
//       console.log("Fill out the form");
//       return;
//     }

//     const data = {
//       name: name,
//       email: email,
//       text: text,
//     };
//     console.log("submitting: ", data);

//     handleContact(data);
//     setName("");
//     setEmail("");
//     setText("");
//   };

//   const handleInput = (e) => {
//     switch (e.target.id) {
//       case "Name":
//         setName(e.target.value);
//         break;
//       case "Email":
//         setEmail(e.target.value);
//         break;
//       case "Text":
//         setText(e.target.value);
//         break;
//       default:
//         console.log("Unknown value: ", e.target.value);
//     }
//     setBlur(blurCalc(name, email, text));
//   }

//   return (
//     <>
//     <h2 style={{marginTop: '2rem', color: 'blue'}}>Need some <strong><em style={{ WebkitFilter: `blur(${blur}px)` }}>clarity</em></strong> in your life?</h2>
//     <div className="formWrapper">
//     <form id="contactForm" onSubmit={handleSubmit} >
//       <input
//         id="Name"
//         className="mb-2 mx-1 rounded"
//         type="text"
//         value={name}
//         placeholder="Name"
//         onChange={(e) => {
//           handleInput(e);
//         }}
//       >
//       </input>
//       <input
//         id="Email"
//         className="mb-2 mx-1 rounded"
//         type="text"
//         value={email}
//         placeholder="Email"
//         onChange={(e) => {
//           handleInput(e);
//         }}
//       ></input>
//       <input
//         id="Text"
//         className="mb-2 mx-1 rounded"
//         type="text"
//         value={text}
//         placeholder="How can we help?"
//         onChange={(e) => {
//           handleInput(e);
//         }}
//       ></input>
//       <input
//         className="mb-2 mx-1 rounded"
//         id="contactButton"
//         type="submit"
//         value="Send a question"
//       />
//     </form>
//     </div>
//     </>
//   );
// };

// export default Contact;
import { useState } from "react";
import { handleContact } from "../../utils/contactHandler";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      text: text,
    };
    console.log("submitting: ", data);

    handleContact(data);

    setName("");
    setEmail("");
    setText("");
  };

  return (
    <form id="contactForm" onSubmit={handleSubmit}>
      <input
        className="mb-2 mx-1 rounded"
        type="text"
        value={name}
        placeholder="Name"
        onChange={(e) => {
          setName(e.target.value);
        }}
      ></input>
      <br></br>
      <input
        className="mb-2 mx-1 rounded"
        type="text"
        value={email}
        placeholder="Email"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      ></input>
      <br></br>
      <textarea
        className="mb-2 mx-1 rounded"
        id="inputBox"
        type="text"
        value={text}
        placeholder="How can we help you?"
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
      <br></br>
      <input
        className="mb-2 mx-1 rounded"
        type="submit"
        value="Submit"
        id="contactButton"
      />
    </form>
  );
};

export default Contact;
